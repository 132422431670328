import React from "react";
import "./Yelp.scss";

const Yelp = () => {
  return (
    <div className="yelp__container">
      <div className="elfsight-app-c2f73514-89ac-40e3-922d-60a8ea685607"></div>
    </div>
  );
};

export default Yelp;
